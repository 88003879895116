










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import ItemCategoryList from "@/components/organisms/item_category/ItemCategoryList.vue";

export default {
  components: {
    SubPageTemplate,
    ItemCategoryList,
  },
};
