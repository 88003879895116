









































































































































import { ItemCategory } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import itemCategoryService from "@/service/itemCategoryService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import ImagePicker from "@/components/molecules/ImagePicker.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/item_category/MixinItemCategoryEditDialogValidator.vue";

@Component({
  components: {
    ProgressLinear,
    ImagePicker,
  },
})
export default class ItemCategoryEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly imagePicker!: ImagePicker;
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: ItemCategory = itemCategoryService.defaultItemCategory;
  toplevelCategories: ItemCategory[] = [];
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get parentCatName(): string {
    if (this.item.parent && this.item.parent.name) {
      return this.item.parent.name;
    } else {
      return "なし";
    }
  }
  get formattedCreatedAt(): string {
    return this.formatDateTime(this.item.createdAt);
  }
  get formattedUpdatedAt(): string {
    return this.formatDateTime(this.item.updatedAt);
  }
  get formattedIsAvailable(): string {
    return this.getIsAvailableLabel(
      this.item.isAvailable ? this.item.isAvailable : false
    );
  }
  //---------------------------
  // methods
  //---------------------------

  async fetchData(
    item: ItemCategory,
    parentItem: ItemCategory | undefined
  ): Promise<void> {
    this.item = itemCategoryService.defaultItemCategory;
    if (item.guid) {
      const cat = await itemCategoryService.getItemCategory(item.guid);
      if (cat) {
        this.item = cat;
      }
    } else {
      //新規追加の場合は親カテゴリを指定
      this.item.parent = parentItem;
    }

    const parents = await itemCategoryService.getToplevelCategories();
    this.toplevelCategories = parents.filter((el) => {
      return el.guid != this.item.guid;
    });
  }

  /**
   * ダイアログを表示します.
   */
  public async open(
    item: ItemCategory,
    parentItem: ItemCategory | undefined
  ): Promise<void> {
    this.isProgressing = true;
    await this.fetchData(item, parentItem);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.imagePicker.reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        itemCategoryService.updateItemCategory(this.item).then((result) => {
          this.isProgressing = true;
          this.notifyUpdateSuccess(result as ItemCategory);
        });
      } else {
        //create
        itemCategoryService.createItemCategory(this.item).then((result) => {
          this.isProgressing = true;
          this.notifyCreateSuccess(result as ItemCategory);
        });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: ItemCategory): ItemCategory {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: ItemCategory): ItemCategory {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
