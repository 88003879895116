























































import { Component, Mixins, Ref } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import itemCategoryService from "@/service/itemCategoryService";
import ItemCategoryDeleteDialog from "@/components/organisms/item_category/ItemCategoryDeleteDialog.vue";
import ItemCategoryEditDialog from "@/components/organisms/item_category/ItemCategoryEditDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import { ItemCategory } from "@/graphql/client";

@Component({ components: { ItemCategoryDeleteDialog, ItemCategoryEditDialog } })
export default class ItemCategoryList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: ItemCategoryEditDialog;
  @Ref() readonly deleteDialog!: ItemCategoryDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: ItemCategory[] = [];

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  public getCategoryLevel(item: ItemCategory | undefined): number {
    let level = 1;
    if (item && item.parent && item.parent.guid) {
      level++;
      const parentItem = item.parent;
      if (parentItem && parentItem.parent && parentItem.parent.guid) {
        level++;
      }
    }
    return level;
  }
  async fetchData(): Promise<void> {
    itemCategoryService.getToplevelCategories().then((items) => {
      this.items = items;
    });
  }

  /**
   * 商品カテゴリ編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(parentItem: ItemCategory | undefined): void {
    this.editDialog.open(
      Object.assign({}, itemCategoryService.defaultItemCategory),
      parentItem
    );
  }

  /**
   * 商品カテゴリ編集ダイアログを表示します.
   */
  public openEditDialog(item: ItemCategory): void {
    this.editDialog.open(Object.assign({}, item), undefined);
  }

  /**
   * 商品カテゴリ編集ダイアログの更新成功時.
   */
  public async onUpdateSuccess(updated: ItemCategory): Promise<void> {
    await this.fetchData();

    this.editDialog.close();
    NotificationState.notifySuccess("商品カテゴリを更新しました！");
  }

  /**
   * 商品カテゴリ編集ダイアログの登録成功時.
   */
  public async onCreateSuccess(created: ItemCategory): Promise<void> {
    await this.fetchData();

    this.editDialog.close();
    NotificationState.notifySuccess("商品カテゴリを登録しました！");
  }

  /**
   * 商品カテゴリ削除ダイアログを表示します.
   */
  public openDeleteDialog(item: ItemCategory): void {
    this.deleteDialog.open(item);
  }

  /**
   * 商品カテゴリ削除ダイアログの処理成功時.
   */
  public async onDeleteSuccess(deleted: ItemCategory): Promise<void> {
    await this.fetchData();
    this.deleteDialog.close();
    NotificationState.notifySuccess("商品カテゴリを削除しました！");
  }
}
